/* BC logo splash */
.bc-splash-enter,
.bc-splash-appear {
  opacity: 0;
}
.bc-splash-enter-active,
.bc-splash-appear-active {
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}
.bc-splash-exit {
  opacity: 1;
}
.bc-splash-exit-active {
  opacity: 0;
  transform: scale(0.4);
  transition: opacity 500ms, transform 300ms;
}
