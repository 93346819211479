@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Balto-Medium";
    font-weight: "medium";
    src: url(assets/fonts/baltoweb-medium-2aea29544e9e5cf6c95b0854cb04df8d.woff2)
      format("woff2");
  }

  @font-face {
    font-family: "Balto";
    font-weight: "bold";
    src: url(assets/fonts/baltoweb-bold-ac24bebbeb76d4e9aa9776ba092bdcea.woff2)
      format("woff2");
  }
  @font-face {
    font-family: "Public-Sans";
    font-weight: "regular";
    src: url(assets/fonts/public-sans-v5-latin-regular-4063c1d7b1b3a9f07cf6a6241fe86cf6.woff2)
      format("woff2");
  }
}
